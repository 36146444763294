<template>
  <div class="material-info">
    <div v-if="materialInfo">
      <div class="title">{{ materialInfo.materialName }}</div>
      <div>
        <figure class="cover">
          <!-- <img src="https://placehold.co/600x400/EEE/31343C" alt=""> -->
          <img :src="materialInfo.materialCoverUrl" :alt="materialInfo.materialName" />
          <caption>人教版</caption>
        </figure>

        <div class="summary">
          <div>
            <span>共 {{ materialInfo.lessonList.length || 0 }} 个课时</span> /
            <span>共 {{ summary.totalCount || 0 }} 道题</span>
          </div>
          <div>
            <span class="item">未打标签：<b class="color-light">{{ summary.unLabelCount || 0 }}</b></span>
            <span class="item">未审核：<b class="color-warn">{{ summary.unReviewCount || 0 }}</b></span>
            <span class="item">未画坐标数量：<b class="color-light">{{ summary.unCoordinateCount || 0 }}</b></span>
          </div>
          <div>
            <el-button class="btn-material" @click="showMaterialPicker">
              <i class="el-icon-sort"></i>
              <span>切换教辅</span>
            </el-button>
          </div>
        </div>
      </div>

      <div class="lessonPicker-title">
        <div>章节和课时</div>

        <div class="subtitle">
          <span class="color-light">“橙色”</span>表示未打标签数量，
          <span class="color-warn">“蓝色”</span>表示未审核数量，
          <span class="color-warn">“绿色”</span>表示总题数
        </div>
      </div>

      <div class="lessonPicker">
        <el-tree
          ref="tree"
          element-loading-text="数据加载中"
          :data="treeData"
          :default-expanded-keys="defaultExpand"
          :props="{
            children: 'children',
            label: 'name'
          }"
          @node-click="handleLessonClick"
          highlight-current
          node-key="key"
        >
          <div class="treeItem" slot-scope="{ node, data }">
            <div class="label">{{ node.label }}</div>
            <div class="num" v-if="data.totalCount">
              (
              <span class="color-light" title="未打标签数量">{{ data.unLabelCount }}</span>
              <span class="color-warn" title="未审核数量">{{ data.unReviewCount }}</span>
              <span class="color-normal" title="总题数">{{ data.totalCount }}</span>
              )
            </div>
          </div>
        </el-tree>
      </div>
    </div>

    <div class="materialSelect" v-else>
      <el-button class="btn-material">
        <i class="el-icon-sort"></i>
        <span>选择教辅</span>
      </el-button>
    </div>

    <ChangeMaterial ref="materialComponents" @change="changeMaterialData" />
  </div>
</template>

<script>
import ChangeMaterial from '../changeMaterial.vue'
import {
  getTeachingMaterialChapter,
  getTeachingMaterialStatistics,
} from '@/api/branchImport.js'
import {
  materialRecordInfo,
} from '@/api/changeMaterial.js'

export default {
  components: {
    ChangeMaterial,
  },
  data () {
    return {
      materialInfo: null,
      summary: {}, // 教材统计信息

      // 章节课时选择
      defaultExpand: [],
      treeData: [],
    }
  },
  computed: {
    // 是否有修订版，源前版本题目不能修改
    childMaterialFlag () {
      return this.materialInfo?.childMaterialFlag === true
    }
  },
  methods: {
    show (params) {
      console.log('--- mounted')
      // FIXME: 用户多窗口操作将导致页面内容与入口参数不一致
      this.getMaterialRecordInfo(params)
    },

    showMaterialPicker () {
      this.$refs.materialComponents.show(this?.materialInfo)
    },

    handleMaterialChange () {
      console.warn('--- handleMaterialChange')
    },

    // 当前教辅信息
    getMaterialRecordInfo (params) {
      // FIXME: 异步问题
      return materialRecordInfo().then(res => {
        if (res.code === 0) {
          // TODO: 校验：与 params 不符，则弹出提示且依据新缓存展示题目
          this.materialInfo = res.data
          if (this.materialInfo) {
            const {
              materialId, // 教辅ID （eg. 基础性作业、同步练习、学习与巩固）
              standardMaterialId, // 教材版本ID（eg. 人教版五年级下册）
            } = this.materialInfo

            this.loadStatistics(standardMaterialId, materialId)
            // - 获取 章节课时 选项
            this.loadChapterOptions(standardMaterialId, materialId, params.classHourId)
              .then(curNode => {
                // 设置展开节点
                const pid = curNode?.parentChapterId
                if (pid) {
                  this.defaultExpand = [curNode]
                }
                this.$refs.tree.setCurrentKey(curNode.key)

                this.submitData(curNode)
              })
          } else {
            this.$refs.materialComponents.show()
          }
        }
      })
    },

    // 切换教辅
    changeMaterialData (data) {
      console.log('-------- 切换教辅', data)
      // TODO: data 参数与 show() 方法一致
      // 更新教辅信息
      this.getMaterialRecordInfo(data).then(() => {
        this.submitData()
      })
    },

    // 加载教辅题目统计信息
    loadStatistics (standardMaterialId, materialId) {
      return getTeachingMaterialStatistics(standardMaterialId, materialId).then(res => {
        this.summary = res.code === 0
          ? res.data
          : {}
      })
    },

    // 加载章节课时选项
    loadChapterOptions (standardMaterialId, materialId, curClassHourId) {
      return getTeachingMaterialChapter(standardMaterialId, materialId).finally(() => {

      }).then(res => {
        const data = res.data
        const curNode = this.formatTreeData(data, curClassHourId)
        this.treeData = data
        // this.pageNumber = curNode?.pageList ? [0] : 1

        return curNode
      })
    },

    // 选中课时
    handleLessonClick (data, e) {
      console.log('----- handleLessonClick', data)
      if (data.type !== 'LESSON') {
        e.isCurrent = false
      } else {
        this.submitData(data)
      }
    },

    // emit组件数据
    submitData (classHourData) {
      const data = {
        subsectionId: this.materialInfo.subsectionId,
        standardMaterialId: this.materialInfo.standardMaterialId,
        materialId: this.materialInfo.materialId,
      }
      this.$emit(
        'changeLesson',
        classHourData
          ? Object.assign(data, {
            classHourId: classHourData.id,
            classHourData: classHourData,
            // chapterId: data.parentChapterId,
          })
          : data
      )
    },

    /**
     * 格式化树 数据接口
     * @param list 章节课时树
     * @param id 默认节点Id
     * @param chapter 课时所属章节
     * 章节ID与课时ID都是自增字段，可能存在冲突 - 新增 key字段
     */
    formatTreeData (list, id, chapter) {
      let curNode = null
      list.forEach(item => {
        if (item.type === 'LESSON') {
          item.chapter = { id: chapter.id, name: chapter.name }
          item.key = chapter.id + '-' + item.id
          if (item.id === id) {
            // 默认选中节点
            curNode = item
          }
        } else {
          item.key = String(item.id)
          item.disabled = true
        }
        if (item.children && item.children.length) {
          const cur = this.formatTreeData(item.children, id, item.type === 'LESSON' ? chapter : item)
          cur && (curNode = cur)
        }
      })
      return curNode
    }
  }
}
</script>

<style lang="scss" scoped>
.material-info {
  padding: 15px;

  .btn-material {
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 20px;
    color: #28B8BA;

    .el-icon-sort {
      font-size: 16px;
      transform: rotateZ(90deg);
    }
  }

  .color-light {
    font-weight: inherit;
    color: #FA6400;
  }
  .color-warn {
    font-weight: inherit;
    color: #1573BA;
  }
  .color-normal {
    font-weight: inherit;
    color: #18C2C2;
  }
}
.title {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
.cover {
  float: left;
  margin-right: 10px;
  position: relative;
  width: 80px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  caption {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.5);
    transform-origin: left top;
    max-width: 200%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    font-size: 20px;
    padding: 4px 6px;
    background-color: rgba(81, 155, 235, 0.5);
    color: #ffffff;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  font-size: 13px;
  color: #999;

  .item {
    margin-right: 20px;
     white-space: nowrap;
    //text-wrap: nowrap;
    // white-space-collapse: collapse;
  }
}

.lessonPicker-title {
  font-size: 15px;
  font-weight: bold;
  color: #000;
  margin: 30px 0;

  .subtitle {
    font-size: 12px;
    font-weight: normal;
    color: #666;
    margin-top: 8px;
  }
}

.materialSelect {
  padding: 80px 0;
  text-align: center;
}

.lessonPicker /deep/ {
  height: calc(100vh - 370px);
  overflow-y: auto;

  .treeItem {
    display: flex;
    flex-wrap: wrap;

    .label {
      margin-right: 1em;
    }

    .num {
      color: #18C2C2;

      span {
        margin: 0 3px;
      }
    }
  }

  .el-tree-node__content {
    height: auto;
    min-height: 26px;
  }

  .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #D0F3F3;
  }
}
</style>
