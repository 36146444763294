import request from '@/utils/request'

/**
 * 根据学段科目ID获取教材信息列表
 */
export function standardMaterialList (subsectionId) {
  return request({
    url: '/basicTeachingMaterial/standard/material/list?subsectionId=' + subsectionId,
    method: 'GET',
    data: null
  })
}

/**
 * 根据教材ID获取教辅信息列表
 */
export function basicMaterialList (standardMaterialId) {
  return request({
    url: '/basicTeachingMaterial/material/list?standardMaterialId=' + standardMaterialId,
    method: 'GET',
    data: null
  })
}

/**
 * 根据录题员获取上次录题教辅信息
 */
export function materialRecordInfo () {
  return request({
    url: '/recordQuestion/material/record/info',
    method: 'GET',
    data: null
  })
}

/**
 * 录题员上次录题教辅信息记录
 */
export function saveMaterialRecord (data) {
  return request({
    url: '/recordQuestion/save/material/record',
    method: 'POST',
    data
  })
}

/**
 * 根据章节获取知识点列表
 * @deprecated 废弃：知识点不再关联章节，直接使用学段学科ID
 */
export function getKnowledgePoints (chapterId) {
  return request({
    url: '/teaching/material/chapter/knowledgePoints/get?chapterId=' + chapterId,
    method: 'GET',
    data: null
  })
}
